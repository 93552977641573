/* In Common.css for Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  padding: 2rem;
}

.modal-carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 8px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  color: #ff4444;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.carousel-button:hover {
  background: #ff4444;
  color: white;
}

.prev {
  left: -2rem;
  font-size: 2rem;
}

.next {
  right: -2rem;
  font-size: 2rem;
}

.close-button {
  position: fixed;
  top: 1rem;
  right: 2rem;
  background: transparent;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s ease;
  padding: 0.5rem;
}

.close-button:hover {
  color: #ff4444;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .prev {
    left: -1.5rem;
  }

  .next {
    right: -1.5rem;
  }
}

@media (max-width: 768px) {
  .modal-content {
    padding: 1rem;
    max-width: 95vw;
  }

  .carousel-button {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }

  .prev {
    left: -1rem;
  }

  .next {
    right: -1rem;
  }

  .close-button {
    font-size: 2rem;
    top: 1rem;
    right: 1rem;
  }
}

@media (max-width: 480px) {
  .carousel-button {
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
  }

  .prev {
    left: -0.5rem;
  }

  .next {
    right: -0.5rem;
  }

  .modal-image {
    max-height: 70vh;
  }
}
